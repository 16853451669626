<template>
  <v-toolbar :color="options.bgColor" flat dense>
    <v-btn
      v-if="showFavouriteBtn"
      v-testid="`entity-card-btn-favourite`"
      icon
      :class="{ shadow: options.shadow }"
      :color="options.textColor"
      @click="$emit('click:favourite')"
    >
      <favourite-icon :value="isFavourite" />
    </v-btn>
    <v-btn
      v-if="showBackBtn"
      v-testid="`entity-card-btn-back`"
      icon
      :class="{ shadow: options.shadow }"
      :color="options.textColor"
      @click="$emit('click:back')"
    >
      <v-icon>$back</v-icon>
    </v-btn>
    <v-toolbar-title
      v-show="options.showTitle"
      class="primary--text font-weight-medium"
    >
      {{ title }}
    </v-toolbar-title>
    <v-spacer />
    <dropdown-menu
      v-if="actions.length > 0"
      item-key-text="name"
      :items="actions"
      offset-y
      @input="$emit('click:action', $event)"
    >
      <template v-slot:activator="{ on }">
        <v-btn
          v-testid="`entity-card-btn-menu`"
          icon
          :class="{ shadow: options.shadow }"
          :color="options.textColor"
          v-on="on"
        >
          <v-icon>$more_vert</v-icon>
        </v-btn>
      </template>
    </dropdown-menu>
    <v-btn
      v-testid="`entity-card-btn-close`"
      icon
      :class="{ shadow: options.shadow }"
      :color="options.textColor"
      @click="$emit('click:close')"
    >
      <v-icon>$close</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { computed } from '@vue/composition-api';
export default {
  name: 'EntityCardToolbar',
  props: {
    transparent: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    showFavouriteBtn: {
      type: Boolean,
      default: false
    },
    showBackBtn: {
      type: Boolean,
      default: false
    },
    isFavourite: {
      type: Boolean,
      default: false
    },
    actions: {
      type: Array,
      default: () => []
    }
  },
  setup(props) {
    const options = computed(() =>
      props.transparent
        ? {
            bgColor: 'transparent',
            textColor: '#686868',
            showTitle: false,
            shadow: true
          }
        : {
            bgColor: undefined,
            textColor: 'primary',
            showTitle: true,
            shadow: false
          }
    );
    return {
      options
    };
  }
};
</script>

<style lang="sass" scoped>
.shadow
  background-color: rgba(255, 255, 255, 0.44) !important
  border-color: rgba(255, 255, 255, 0.44) !important
::v-deep
  .v-toolbar__extension
    padding-left: 0 !important
    padding-right: 0 !important
  .v-toolbar__content > .v-btn.v-btn--icon
    margin-left: 4px
    margin-right: 4px
</style>
